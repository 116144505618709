import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {
        translation: {
            'contact': 'CONTACT',
            'privacy': 'PRIVACY POLICY',
            'post': {
                'latest': 'Latest Post',
                'tags': 'Tags',
                'created': 'create:',
                'updated': 'update:',
            },
            'contactpage': {
                'greeting': 'For work requests, etc., please contact us here.',
                'name': 'Name',
                'email': 'E-Mail',
                'subject': 'Subject',
                'message': 'Message',
                'send': 'Send Message',
                'done_title': 'Thank you for sending message!',
                'done_text_1': 'Thank you for your message. Your message has been received. We will contact you again after confirming the contents, so please wait for a while.',
                'done_text_2': 'For confirmation, an automatic reply e-mail will be sent to the e-mail address you entered.',
                'done_text_3': 'If you do not receive the e-mail, it is possible that the e-mail address you entered is incorrect, so please contact us again.',
                'done_button': 'Back to contact',
            },
            'privacypage': {
                'greeting': 'This website is a site that introduces the achievements of Hyblinx.',
                'title_1': 'Privacy policy',
                'title_2': 'Management of personal information',
                'title_3': 'Purpose of use of personal information',
                'title_4': 'Prohibition of Disclosure/Provision of Personal Information to Third Parties',
                'title_5': 'Security measures for personal information',
                'title_6': 'Access analysis tools',
                'title_7': 'Personal inquiry',
                'title_8': 'Observance and review of laws and regulations',
                'title_9': 'Disclaimer',
                'title_10': 'Copyright',
                'text_1': 'This site establishes the personal information protection policy as follows, builds a mechanism for personal information protection, and promotes the protection of personal information.',
                'text_2': 'In order to keep customer\'s personal information accurate and up-to-date, and to prevent unauthorized access, loss, damage, falsification, leakage, etc. of personal information, this site maintains a security system, develops a management system, and provides employee training. We will take necessary measures such as thorough implementation of safety measures and strictly manage personal information.',
                'text_3': 'This site may ask you to register personal information such as your name, e-mail address, and phone number when you make an inquiry, but we will not use this personal information for any purpose other than the purpose of providing it. yeah. The personal information entrusted to us by our customers will be used for sending e-mails and documents as a response from this site, business guidance, and questions.',
                'text_4': 'This site properly manages personal information entrusted to us by customers and does not disclose personal information to third parties except in the following cases.',
                'text_5': '・With customer\'s consent',
                'text_6': '・When disclosing to a contractor outsourced to perform the service desired by the customer',
                'text_7': '・When disclosure is required by law',
                'text_8': 'This site takes thorough security measures to ensure the accuracy and safety of personal information.',
                'text_9': 'This site uses Google\'s access analysis tool "Google Analytics". This Google Analytics uses cookies to collect traffic data. This traffic data is collected anonymously and is not personally identifiable. It is also possible to prevent the use of cookies through browser settings.',
                'text_10': 'If a customer wishes to inquire, modify, or delete their own personal information, we will respond after confirming the identity of the customer.',
                'text_11': 'This site will comply with Japanese laws and other norms applicable to the personal information it holds, review the content of this policy as appropriate, and strive to improve it.',
                'text_12': 'We do not take any responsibility for the information, services, etc. provided on sites that are moved by links or banners from this site.',
                'text_13': 'In addition, we strive to provide as accurate information as possible regarding the contents and information of this site, but we do not guarantee accuracy or safety. Information may be outdated.',
                'text_14': 'Please note that we are not responsible for any damages caused by the content posted on this site.',
                'text_15': 'The copyright of the texts, images, videos, etc. posted on this site belongs to this site. Unauthorized reproduction of this information is prohibited.',
            },
            'year': ' years',
            'fukuoka': 'Fukuoka',
            'tokyo': 'Tokyo',
            'charge': {
                'designer': 'Designer',
                'webanalyst': 'Web Analyst',
                'supportdesk': 'Support Desk',
                'coder': 'Coder',
                'programmer': 'Programmer',
                'softwaredeveloper': 'Software Developer',
                'projectmanager': 'Project Manager',
            },
            'discography_1': {
                'name': 'Logistics IT vendor',
                'charge': 'Programmer',
            },
            'discography_2': {
                'name': 'Driving School',
                'charge': 'Web Designer, Advertisor/Illustrator/Video producer, Website Developer/Maintenaner/Operator, Driving instructor, Bus Driver, Bicycle driving instructor, etc...',
            },
            'discography_3': {
                'name': 'IT venture of trading company subsidiary',
                'charge': 'Project Manager, Software Developer, Programmer',
            },
            'discography_4': {
                'name': 'Torana, Inc.',
                'charge': 'Software Developer, Programmer',
            },
            'discography_5': {
                'name': '(Food Company)',
                'charge': 'Software Developer, Programmer',
            },
            'summary_1': 'I am a software engineer whose main area is web backend.',
            'summary_2': 'I focus on development that meets needs by dialogues with on-site members.',
            'summary_3': 'I have a lot of development experiences with Laravel + MySQL + AWS.',
            'profile_1': {
                'column': 'Location',
                'content': 'Fukuoka, Japan',
            },
            'profile_2': {
                'column': 'Hobby',
                'content': 'Software Development, Travel, Town walk, Work out',
            },
            'profile_3': {
                'column': 'Music',
                'content': 'Heavy Metal',
            },
            'about_1': 'This is the portfolio site of an web back-end/front-end engineer, Sho.',
            'about_2': 'The site name is read as "Hyb-linx". It is a coined word that combines the abbreviation "hyb", which stands for mixed species, and "links", which means multiple connections. It is a name that expresses my desire to connect things that were completely unrelated through my own activities.',
            'about_3': 'In the first place, individual site names and logos are not essential for individual production, but when I was in a position to undertake and carry out my work as an individual, I felt the need for words that would be the axis of my activities, so I decided to name the site. I have set it.',
            'policy_1': 'It is my personal production philosophy. I would like to produce with a focus on making it fun just by looking at it with a vivid design, being sophisticated with a sense of unity, and being easy to handle.',
            'policy_2': '`VIVID` is mainly a matter of consideration when designing, but `SOLID` and `REFINED` place value in any work related to service development. This is because we believe that being sophisticated and easy to maintain and operate are essential values ​​for creating good services.',
            'skill_header_1': 'Coding languages/Frame Works',
            'skill_header_2': 'Databases',
            'skill_header_3': 'Infrastructures',
            'skill_header_4': 'Tool Experiences',
            'skill_header_5': 'Other Experiences',
            'skill_tool_1': 'Server Monitoring',
            'skill_tool_2': 'CI/CD',
            'skill_tool_3': 'Project Management',
            'skill_tool_4': 'VCS',
            'skill_tool_5': 'Knowledge Sharing',
            'skill_tool_6': 'Communication Tools',
            'skill_tool_7': 'Payment Service',
            'skill_tool_8': 'Others',
            'skill_experience_1': 'Investigation and introduction of unified standard JPQR (EMV) for payment QR code',
            'skill_experience_2': 'Experience in introducing 3D secure authentication and convenience store payment using API of payment agency VeriTrans',
            'skill_experience_3': 'Investigation and introduction of API specifications for Amazon and 6 EC mall services',
            'project_1': 'Warehouse Management packaged software development PJ',
            'project_2': 'Enterprise Information Portal development PJ',
            'project_3': 'Accounting system maintenance and operation PJ',
            'project_4': 'Driving School website renewal PJ',
            'project_5': 'QR code payment service development PJ',
            'project_6': 'EC operation support system development PJ',
            'project_7': 'Core system for sales and inventories management development PJ',
            'project_8': 'Corporate Website renewal PJ',
            'project_9': 'Framework migration and server transfer PJ',
            'project_10': 'Ordering system development PJ',
            'project_11': 'Core system for Customers and inventories management development PJ',
            'project_12': 'EC/Product website development PJ',
        }
    },
    ja: {
        translation: {
            'contact': '問い合わせ',
            'privacy': 'プライバシーポリシー',
            'post': {
                'latest': '最近の投稿',
                'tags': 'タグ一覧',
                'created': '投稿日',
                'updated': '更新日',
            },
            'contactpage': {
                'greeting': 'お仕事の依頼等につきましてはこちらからお問い合わせください。',
                'name': 'お名前',
                'email': 'メールアドレス',
                'subject': 'ご用件',
                'message': '内容',
                'send': '送信',
                'done_title': 'お問い合わせ完了',
                'done_text_1': 'お問い合わせいただきありがとうございます。送信内容を受け付けました。内容を確認後改めてご連絡いたしますので、しばらくお待ちください。',
                'done_text_2': 'なお確認のため、ご入力いただいたメールアドレス宛に自動返信メールをお送りしております。',
                'done_text_3': 'もしメールが届いていない場合はご入力いただいたメールアドレスが間違っている可能性がありますので、お手数ですが、再度お問い合わせください。',
                'done_button': '問い合わせ画面へ戻る',
            },
            'privacypage': {
                'greeting': '本ウェブサイトはHyblinxの実績等を紹介するサイトです。',
                'title_1': '個人情報保護方針',
                'title_2': '個人情報の管理',
                'title_3': '個人情報の利用目的',
                'title_4': '個人情報の第三者への開示・提供の禁止',
                'title_5': '個人情報の安全対策',
                'title_6': 'アクセス解析ツールについて',
                'title_7': 'ご本人の照会',
                'title_8': '法令、規範の遵守と見直し',
                'title_9': '免責事項',
                'title_10': '著作権',
                'text_1': '当サイトは、以下のとおり個人情報保護方針を定め、個人情報保護の仕組みを構築し、個人情報の保護を推進いたします。',
                'text_2': '当サイトは、お客さまの個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持・管理体制の整備・社員教育の徹底等の必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行ないます。',
                'text_3': '本サイトでは、お客様からのお問い合わせ時に、お名前、e-mailアドレス、電話番号等の個人情報をご登録いただく場合がございますが、これらの個人情報はご提供いただく際の目的以外では利用いたしません。お客さまからお預かりした個人情報は、当サイトからのご連絡や業務のご案内、ご質問に対する回答として、電子メールや資料のご送付に利用いたします。',
                'text_4': '当サイトでは、お客さまよりお預かりした個人情報を適切に管理し、次のいずれかに該当する場合を除き、個人情報を第三者に開示いたしません。',
                'text_5': '・お客さまの同意がある場合',
                'text_6': '・お客さまが希望されるサービスを行なうために業務を委託する業者に対して開示する場合',
                'text_7': '・法令に基づき開示することが必要である場合',
                'text_8': '当サイトでは、個人情報の正確性及び安全性確保のために、セキュリティに万全の対策を講じています。',
                'text_9': '当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。 このGoogleアナリティクスはトラフィックデータの収集のためにCookieを使用しています。このトラフィックデータは匿名で収集されており、個人を特定するものではありません。ブラウザの設定により、Cookieを使用しないようにすることも可能です。',
                'text_10': 'お客さまがご本人の個人情報の照会・修正・削除などをご希望される場合には、ご本人であることを確認の上、対応させていただきます。',
                'text_11': '当サイトでは、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。',
                'text_12': '当サイトからのリンクやバナーなどで移動したサイトで提供される情報、サービス等について一切の責任を負いません。',
                'text_13': 'また当サイトのコンテンツ・情報について、できる限り正確な情報を提供するように努めておりますが、正確性や安全性を保証するものではありません。情報が古くなっていることもございます。',
                'text_14': '当サイトに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。',
                'text_15': '当サイトに掲載している、文章・画像・動画等の著作権は当サイトに帰属します。これらの情報を無断転載することを禁止します。',
            },
            'year': '年',
            'fukuoka': '福岡',
            'tokyo': '東京',
            'charge': {
                'designer': 'デザイナー',
                'webanalyst': 'Webアナリスト',
                'supportdesk': 'サポートデスク',
                'coder': 'コーダー',
                'programmer': 'プログラマー',
                'softwaredeveloper': 'システムエンジニア',
                'projectmanager': 'プロジェクトリーダー',
            },
            'discography_1': {
                'name': '物流系ITベンダー',
                'charge': 'プログラマー',
            },
            'discography_2': {
                'name': '自動車学校',
                'charge': 'Webデザイン、広告/イラスト/動画制作、Webサイト開発/保守/運営、教習指導員、送迎バス運転、自転車講習講師等',
            },
            'discography_3': {
                'name': '商社傘下のITベンチャー',
                'charge': 'プロジェクトリーダー、システムエンジニア、プログラマー',
            },
            'discography_4': {
                'name': '株式会社トラーナ',
                'charge': 'システムエンジニア、プログラマー',
            },
            'discography_5': {
                'name': '食品メーカー勤務',
                'charge': 'システムエンジニア、プログラマー',
            },
            'summary_1': 'Webバックエンドをメイン領域とするエンジニアです',
            'summary_2': 'ニーズに沿った開発を重視し、現場メンバーとの対話を積極的に行います',
            'summary_3': 'Laravel + MySQL + AWSの開発経験が豊富です',
            'profile_1': {
                'column': '居住地',
                'content': '福岡',
            },
            'profile_2': {
                'column': '趣味',
                'content': 'システム開発、旅行、街歩き、筋トレ',
            },
            'profile_3': {
                'column': '音楽',
                'content': 'ヘヴィメタル',
            },
            'about_1': '本サイトはWebエンジニア、田中 翔のポートフォリオサイトです。',
            'about_2': 'サイト名は "Hyblinx"（はいぶりんくす）です。混合種を表す略語の"hyb"と、複数のつながりを意味する"links"を組み合わせた造語です。全く関連のなかった2つのものを僕の活動を通じてつなげられるような活動をしたいという想いを込めた名前です。',
            'about_3': 'Hyblinxは個人開発・制作のキャリアをスタートしたときに制作したサイトです。自分なりに活動の軸となるイメージをこのサイトで言語化・具現化しました。',
            'policy_1': '個人としての制作理念です。鮮やかなデザインで見ているだけで楽しくなること、統一感があり洗練されていること、扱いやすいことを軸として制作を行いたいと考えております。',
            'policy_2': 'VIVIDは主にデザイン等を行う場合の留意事項ですが、SOLID、REFINEDはサービス開発に関するいずれの業務においても価値を置いております。洗練されていて、かつ保守・運用しやすいことはいいサービスを作る上で欠かせない価値観だと考えるためです。',
            'skill_header_1': '開発言語/フレームワーク',
            'skill_header_2': 'データベース',
            'skill_header_3': 'インフラ関連',
            'skill_header_4': 'ツールの使用経験',
            'skill_header_5': 'その他経験など',
            'skill_tool_1': 'サーバー監視',
            'skill_tool_2': 'CI/CD',
            'skill_tool_3': 'プロジェクト管理',
            'skill_tool_4': 'バージョン管理',
            'skill_tool_5': 'ナレッジ共有',
            'skill_tool_6': '会話ツール',
            'skill_tool_7': '決済サービス',
            'skill_tool_8': 'その他',
            'skill_experience_1': '決済用QRコードの統一規格JPQR(EMV)の調査・導入',
            'skill_experience_2': '決済代行会社VeriTransのAPIを用いた3Dセキュア認証およびコンビニ決済の導入経験',
            'skill_experience_3': 'Amazonほか6件のECモールのAPI仕様の調査・導入',
            'project_1': '倉庫管理パッケージ開発PJ',
            'project_2': '社内向けポータル開発PJ',
            'project_3': '経理システム保守運用PJ',
            'project_4': '自動車学校ウェブサイトリニューアルPJ',
            'project_5': 'QRコード決済サービス開発PJ',
            'project_6': 'EC運営支援システム開発PJ',
            'project_7': '売上・在庫管理向け基幹システム開発PJ',
            'project_8': 'コーポレートWEBサイトリニューアルPJ',
            'project_9': '自動車学校ウェブサイトのF/W移行、サーバー移管PJ',
            'project_10': '受発注システム構築PJ',
            'project_11': '顧客・在庫管理向け基幹システム開発PJ',
            'project_12': 'EC・製品サイト開発PJ',
        }
    },
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "ja",
        debug: true,
        interpolation: {
            escapeValue: false,
        }
    });

export default i18n;