import { useTranslation } from 'react-i18next';
import SkillMap from './components/SkillMap';

const About = () => {

  const {t} = useTranslation();

  return (
    <div className="About">
      <section>
        <div className="about">
          <div className="new_about">
            <div className="base">
              <h1>Sho</h1>
              <h2>SOFTWARE ENGINEER</h2>
            </div>
          </div>
        </div>
      </section>
      <section className="frame white">
        <div className="about">
          <div className="new_about">
            <div className="profile animation-scroll-show">
              <h3>PROFILE</h3>
              <ul>
                <li>
                  <h4>DISCOGRAPHY</h4>
                  <dl>
                    <dt>2014 - 2017</dt>
                    <dd>
                        <h2>{ t('discography_1.name') }<span> - { t('fukuoka') }</span></h2>
                        <p>{ t('discography_1.charge') }</p>
                    </dd>
                    <dt>2017 - 2019</dt>
                    <dd>
                        <h2>{ t('discography_2.name') }<span> - { t('fukuoka') }</span></h2>
                        <p>{ t('discography_2.charge') }</p>
                    </dd>
                    <dt>2019 - 2022</dt>
                    <dd>
                        <h2>{ t('discography_3.name') }<span> - { t('fukuoka') }</span></h2>
                        <p>{ t('discography_3.charge') }</p>
                    </dd>
                    <dt>2022 - 2023</dt>
                    <dd>
                        <h2>{ t('discography_4.name') }<span> - { t('tokyo') }</span></h2>
                        <p>{ t('discography_4.charge') }</p>
                    </dd>
                    <dt>2024 -</dt>
                    <dd>
                        <h2>{ t('discography_5.name') }<span> - { t('tokyo') }</span></h2>
                        <p>{ t('discography_5.charge') }</p>
                    </dd>
                  </dl>
                </li>
                <li>
                  <h4>SKILL SUMMARY</h4>
                  <ul className="summary">
                    <li>{ t('summary_1') }</li>
                    <li>{ t('summary_2') }</li>
                    <li>{ t('summary_3') }</li>
                  </ul>
                  <h4>BASIC INFORMATION</h4>
                  <table>
                    <tr>
                      <th>{ t('profile_1.column') }</th>
                      <td>{ t('profile_1.content') }</td>
                    </tr>
                    <tr>
                      <th>{ t('profile_2.column') }</th>
                      <td>{ t('profile_2.content') }</td>
                    </tr>
                    <tr>
                      <th>{ t('profile_3.column') }</th>
                      <td>{ t('profile_3.content') }</td>
                    </tr>
                  </table>
                  <ul className="sns">
                    <li><a href={process.env.REACT_APP_SNS_URL_TWITTER}><img src="/img/sns_x.png" alt="sns_x" /></a></li>
                    <li><a href={process.env.REACT_APP_SNS_URL_GITHUB}><img src="/img/sns_github.png" alt="sns_github" /></a></li>
                    <li><a href={process.env.REACT_APP_SNS_URL_LINKEDIN}><img src="/img/sns_linkedin.png" alt="sns_linkedin" /></a></li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="frame light-gray">
        <div className="about">
          <div className="new_about">
            <div className="new_about2 animation-scroll-show">
              <h3>ABOUT</h3>
              <p>{ t('about_1') }</p>
              <p>{ t('about_2') }</p>
              <p>{ t('about_3') }</p>
            </div>
          </div>
        </div>
      </section>
      <section className="frame theme">
        <div className="about">
          <div className="new_about">
            <div className="policy animation-scroll-show">
              <div className="catch_copy">
                <h5>VIVID. <span className="sp_only"><br /></span>SOLID. <span className="sp_only"><br /></span>REFINED.</h5>
              </div>
              <p>{ t('policy_1') }</p>
              <p>{ t('policy_2') }</p>
            </div>
          </div>
        </div>
      </section>
      <SkillMap />
      <section className="frame light-gray">
        <div className="about">
          <div className="new_about">
            <div className="project animation-scroll-show">
              <h3>PROJECT</h3>
              <ul>
                <li>
                  <div className="during">2014 - 2016</div>
                  <div className="title">{ t('project_1') }<span> - { t('fukuoka') }</span></div>
                  <div className="charge">{ t('charge.programmer') }</div>
                </li>
                <li>
                  <div className="during">2016 - 2016</div>
                  <div className="title">{ t('project_2') }<span> - 東京</span></div>
                  <div className="charge">{ t('charge.programmer') }</div>
                </li>
                <li>
                  <div className="during">2017 - 2017</div>
                  <div className="title">{ t('project_3') }<span> - { t('fukuoka') }</span></div>
                  <div className="charge">{ t('charge.supportdesk') }、{ t('charge.programmer') }</div>
                </li>
                <li>
                  <div className="during">2017 - 2019</div>
                  <div className="title">{ t('project_4') }<span> - { t('fukuoka') }</span></div>
                  <div className="charge">{ t('charge.designer') }、{ t('charge.coder') }、{ t('charge.softwaredeveloper') }、{ t('charge.webanalyst') }、{ t('charge.supportdesk') }</div>
                </li>
                <li>
                  <div className="during">2019 - 2021</div>
                  <div className="title">{ t('project_5') }<span> - { t('fukuoka') }</span></div>
                  <div className="charge">{ t('charge.projectmanager') }、{ t('charge.softwaredeveloper') }</div>
                </li>
                <li>
                  <div className="during">2021 - 2021</div>
                  <div className="title">{ t('project_6') }<span> - { t('fukuoka') }</span></div>
                  <div className="charge">{ t('charge.softwaredeveloper') }</div>
                </li>
                <li>
                  <div className="during">2021 - 2022</div>
                  <div className="title">{ t('project_7') }<span> - { t('fukuoka') }</span></div>
                  <div className="charge">{ t('charge.projectmanager') }、{ t('charge.softwaredeveloper') }</div>
                </li>
                <li>
                  <div className="during">2021 - 2022</div>
                  <div className="title">{ t('project_8') }<span> - { t('fukuoka') }</span></div>
                  <div className="charge">{ t('charge.softwaredeveloper') }</div>
                </li>
                <li>
                  <div className="during">2021 - 2022</div>
                  <div className="title">{ t('project_9') }<span> - { t('fukuoka') }</span></div>
                  <div className="charge">{ t('charge.softwaredeveloper') }</div>
                </li>
                <li>
                  <div className="during">2021 - 2022</div>
                  <div className="title">{ t('project_10') }<span> - { t('fukuoka') }</span></div>
                  <div className="charge">{ t('charge.softwaredeveloper') }</div>
                </li>
                <li>
                  <div className="during">2021 - 2023</div>
                  <div className="title">{ t('project_11') }<span> - { t('tokyo') }</span></div>
                  <div className="charge">{ t('charge.softwaredeveloper') }</div>
                </li>
                <li>
                  <div className="during">2024 -</div>
                  <div className="title">{ t('project_12') }<span> - { t('tokyo') }</span></div>
                  <div className="charge">{ t('charge.softwaredeveloper') }</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default About;