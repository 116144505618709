import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import NoticeCard from "./components/NoticeCard";
import { Work } from "./types/Work";
import { Zenn } from "./types/Zenn";
import { Note } from "./types/Note";
import { LatestPost } from "./types/LatestPost";

const Home = () => {

  const [latest_post, setLatestPost] = useState<LatestPost>([] as any);
  const [notes, setNotes] = useState<Array<Note>>([] as any);
  const [zenns, setZenns] = useState<Array<Zenn>>([] as any);
  const [works, setWorks] = useState<Array<Work>>([] as any);
  const {t} = useTranslation();

  // 先ほど作成したLaravelのAPIのURL
  const url = process.env.REACT_APP_API_URL + "/api/home";

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(url);
        setLatestPost(res.data.latest_post);
        setNotes(res.data.notes);
        setZenns(res.data.zenns);
        setWorks(res.data.works);

        document.getElementById("notice")!.style.marginRight = "0";
        var target_id = '';
        for (var i = 1; i < 5; i++) {
          target_id = 'signal0' + i;
          document.getElementById(target_id)!.style.backgroundColor = "#333";
        }
        return;
      } catch (e) {
        return e;
      }
    })();
  }, [url]);

  return (
    <div id="Home" className="Home">
      <section>
        <img className="top_img" src='/img/top.jpg' />
        <NoticeCard latestPost={latest_post} />
      </section>
      <section className="frame white">
        <div className="top-list animation-scroll-show">
          <div className="post_content post_top pc_only">
            <div className="main_box">
              <div className="list">
                <ul>
                  <li className="list-index">
                    <a href="https://note.com/sho_advent">
                      <img src="/img/logo_note.png" />
                      <span>read more...<i className="bi bi-chevron-right"></i></span>
                    </a>
                  </li>
                  {notes.map((note) => {
                    return (
                      <li>
                        <a href={ note.url }>
                          <img src={ note.image } />
                          <div className="overview">
                            <i className="bi bi-calendar3"></i>
                            <p>{ note.created_at_display }</p>
                            <i className="bi bi-heart-fill note-suki"></i>
                            <p className="note-suki">{ note.likes }</p>
                            <i className="bi bi-chat-dots"></i>
                            <p>{ note.comments }</p>
                          </div>
                          <div className="title">
                            <p>{ note.title }</p>
                          </div>
                        </a>
                      </li>
                    );
                  })}
                </ul>
                <ul>
                  {zenns.map((zenn) => {
                    return (
                      <li>
                        <a href={ zenn.url } className="zenn">
                          {
                            (() => {
                              if (zenn.article_type === 'idea') {
                                return (
                                  <div className="thumbnail idea">
                                    <p className="emoji">{ zenn.emoji }</p>
                                    <p className="title">{ zenn.title }</p>
                                  </div>
                                );
                              } else if (zenn.article_type === 'tech') {
                                return (
                                  <div className="thumbnail tech">
                                    <p className="emoji">{ zenn.emoji }</p>
                                    <p className="title">{ zenn.title }</p>
                                  </div>
                                );
                              } else {
                                return (
                                  <img src={process.env.REACT_APP_API_URL + '/img/noimage.png'} />
                                );
                              }
                            })()
                          }
                          <div className="overview">
                            <i className="bi bi-calendar3"></i>
                            <p>{ zenn.created_at_display }</p>
                            <i className="bi bi-heart-fill note-suki"></i>
                            <p className="note-suki">{ zenn.likes }</p>
                            <i className="bi bi-chat-dots"></i>
                            <p>{ zenn.comments }</p>
                          </div>
                        </a>
                      </li>
                    );
                  })}
                  <li className="list-index">
                    <a href="https://zenn.dev/hyblinx">
                      <img src="/img/logo_zenn.png" />
                      <span>read more...<i className="bi bi-chevron-right"></i></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="post_content post_top sp_only">
            <div className="main_box">
              <div className="list">
                <h1>
                  <a href="https://note.com/sho_advent">
                    <img src="/img/logo_note.png" />
                  </a>
                </h1>
                <ul>
                  {notes.map((note) => {
                    return (
                      <li>
                        <a href={ note.url }>
                          <img src={ note.image } />
                          <div className="overview">
                            <i className="bi bi-calendar3"></i>
                            <p>{ note.created_at_display }</p>
                            <i className="bi bi-heart-fill note-suki"></i>
                            <p className="note-suki">{ note.likes }</p>
                            <i className="bi bi-chat-dots"></i>
                            <p>{ note.comments }</p>
                          </div>
                          <div className="title">
                            <p>{ note.title }</p>
                          </div>
                        </a>
                      </li>
                    );
                  })}
                </ul>
                <h1>
                  <a href="https://zenn.dev/hyblinx">
                    <img src="/img/logo_zenn.png" />
                  </a>
                </h1>
                <ul>
                  {zenns.map((zenn) => {
                    return (
                      <li>
                        <a href={ zenn.url } className="zenn">
                          {
                            (() => {
                              if (zenn.article_type === 'idea') {
                                return (
                                  <div className="thumbnail idea">
                                    <p className="emoji">{ zenn.emoji }</p>
                                    <p className="title">{ zenn.title }</p>
                                  </div>
                                );
                              } else if (zenn.article_type === 'tech') {
                                return (
                                  <div className="thumbnail tech">
                                    <p className="emoji">{ zenn.emoji }</p>
                                    <p className="title">{ zenn.title }</p>
                                  </div>
                                );
                              } else {
                                return (
                                  <img src={process.env.REACT_APP_API_URL + '/img/noimage.png'} />
                                );
                              }
                            })()
                          }
                          <div className="overview">
                            <i className="bi bi-calendar3"></i>
                            <p>{ zenn.created_at_display }</p>
                            <i className="bi bi-heart-fill note-suki"></i>
                            <p className="note-suki">{ zenn.likes }</p>
                            <i className="bi bi-chat-dots"></i>
                            <p>{ zenn.comments }</p>
                          </div>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="frame light-gray">
        <div className="top-list animation-scroll-show">
          <h1 className="top">WORKS</h1>
          <div className="work_box_top">
            <ul>
              {works.map((work) => {
                return (
                  <li>
                    <a href={'/works/' + work.work_id }>
                      <img src={ work.image } />
                    </a>
                    <div className="during">
                      <p>{ work.worked_at }<span>　{ work.type }</span></p>
                    </div>
                    <a href={'/works/' + work.work_id } className="title">{ work.title }</a>
                    <p className="type">{ work.type_detail }</p>
                  </li>
                );
              })}
            </ul>
          </div>
          <a className="link-button" href="/works">Read more　<i className="bi bi-chevron-right"></i></a>
        </div>
      </section>
      <section className="frame">
        <div className="about-top animation-scroll-show">
          <h1>Sho</h1>
          <h2>SOFTWARE ENGINEER</h2>
          <ul className="summary">
            <li>{ t('summary_1') }</li>
            <li>{ t('summary_2') }</li>
            <li>{ t('summary_3') }</li>
          </ul>
        </div>
        <a className="link-button animation-scroll-show" href="/about">Read more　<i className="bi bi-chevron-right"></i></a>
      </section>
    </div>
  );
}

export default Home;